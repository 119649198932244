<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="title">岗位</div>
            <div class="text">{{name}}</div>
          </div>
          <div class="item">
            <div class="title">负责人</div>
            <div class="text">{{usernames.length === 0? '暂无': usernames.join(', ')}}</div>
          </div>
          <div class="item">
            <div class="title">备注</div>
            <div class="text">{{remark}}</div>
          </div>
        </div>
        <img class="img" :src="image" />
        <div class="tip">此岗位二维码供打印</div>
      </div>
      <div class="rb">
        <img src="@/assets/blueadd.png" class="icon" />
        <router-link to="/position/add">
          <div class="text">继续添加岗位</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import { isEmpty, isTel } from '@/utils';
import qs from 'qs';
export default {
  data() {
    return {
      name: '',
      remark: '',
      usernames: [],
      image: ''
    };
  },
  async created() {
    let id = this.$route.params.id;
    let { data } = await this.axios.get('/ctid/tlService/selectiddoor', { params: { client_id: id } });
    if (data.flag !== '0') {
      Dialog({ message: data.message });
    } else {
      data = data.data;
      this.name = data.client_name;
      this.remark = data.client_memo;
      this.usernames = data.listusermw.map(x => x.user_name);
      this.image = data.user_notes;
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.img {
  width: 400px;
  display: block;
  margin: 32px auto 0;
}
.tip {
  text-align: center;
  font-size: 28px;
  line-height: 40px;
  margin-top: 20px;
}
.rb {
  height: 72px;
  width: 278px;
  overflow: hidden;
  border-radius: 38px;
  border: 2px solid rgba(0, 29, 255, 1);
  margin: 60px auto 60px auto;
  .icon {
    width: 48px;
    height: 48px;
    margin: 10px;
    display: block;
    float: left;
  }
  .text {
    float: left;
    color: rgba(0, 29, 255, 1);
    font-size: 30px;
    line-height: 72px;
  }
}
</style>